<template>
  <div class="article-box" v-loading="loading">
    <!-- 标题 -->
    <div class="pag-title clearfix" style="margin-top:0">
        <div class="page-title-name pull-left">交流讨论</div>
        <div class="page-title-button pull-right" @click="addMsg">添加新消息</div>
    </div>
    <!-- 表格 -->
    <div class="table-box" v-if="totalCount">
      <table>
          <thead>
              <tr>
                  <th style="width: 70%;text-align: left;padding-left: 25px">标题</th>
                  <th style="width: 160px;text-align: center;">时间</th>
              </tr>
          </thead>
          <tbody>
              <tr v-for="(item) in listData" :key="item.id" @click="toDetail(item.id)" style="cursor: pointer;">
                  <td style="width: 70%;text-align: left;padding-left: 25px;position: relative">
                    <span>{{ item.nickName || '-' }}</span>
                    <div class="hot-dot" v-if="item.isRead == 2"></div>
                  </td>
                  <td style="width: 160px;text-align: center;">{{ item.createDate || '-'}}</td>
              </tr>
          </tbody>
      </table>
      <el-pagination
          style="text-align: right;padding-top: 20px;"
          @size-change="pageSizeHandle"
          @current-change="pageChangeHandle"
          :page-sizes="[10, 20, 30, 40, 50, 60, 70, 80]"
          layout="total, sizes, prev, pager, next, jumper"
          :page-size="pageSize"
          :current-page="pageNo"
          :page-count="totalPage"
          :total="totalCount">  
      </el-pagination>
    </div>
    <div class="no-data-img"  v-if="noData"></div>
  </div>
</template>
<script>
import { DiscussionList } from "@/http/api/InitiationApi";

export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {
      loading: false,
      listData: [],
      keyword: '',
      noData: false,
      totalPage: 0,
      totalCount: 0,
      pageNo: 1,
      pageSize: 10,
    };
  },
  computed: {},
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true
      DiscussionList({
        pageNo: this.pageNo || 1,
        pageSize: this.pageSize || 10,
      }).then(resp => {
        this.loading = false
        if (resp.code === "000") {
          this.totalPage = resp.result.pages;
          this.totalCount = resp.result.total;
          this.pageNo = resp.result.current
          this.listData = resp.result.records;
          if (!resp.result.total) {
              this.noData = true
          }
        }
      });
    },
    pageSizeHandle (val) {
        this.pageNo = 1;
        this.pageSize = val;
        this.getList();
    },
    pageChangeHandle (val) {
        this.pageNo = val;
        this.getList();
    },
    toDetail (id) {
      this.$router.push({
        path: "/institution/communication/detail",
        query: {
          id: id,
        },
      });
    },
    addMsg () {
      this.$router.push({path: '/institution/communication/add'})
    }
  },
};
</script>
<style lang='less' scoped>
.no-data-img {
    width: 100%;
    height: 300px;
    background: url("../../assets/images/nodata.png") no-repeat center center;
}
ul li {
  width: 90%;
  height: 50px;
  line-height: 50px;
  border: 1px solid #ccc;
  background-color: #eee;
  margin: 10px;
  display: flex;
  justify-content: space-between;
}
ul li span {
  width: 35%;
  text-align: center;
}
.add {
  width: 100%;
  text-align: center;
}
.red {
  color: red;
  border: 5px red solid;
  border-radius: 5px;
  width: 5px;
  height: 5px;
}

.hot-dot {
  background-color: #C30000 ;
  border-radius: 4px;
  width: 8px;
  height: 8px;
  position:absolute;
  top: 26px;
  left: 8px;
}
</style>
  